.folder-container{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

table {
    width: 100%;
    max-width: 700px;
    margin-bottom: 2rem;
    background-color: #fff;

}

tr{
    display: table-row;
    vertical-align: inherit;
    border-color: inherit;
}


thead{
    font-size: 25px;
    margin: 30px;
    padding-bottom: 20px;


}
thead td{
    padding-bottom: 50px;

}

tbody tr:nth-child(even) {
    background-color: #f5f5f5;
}

tbody tr {
    font-size: 15px;
    color: #808080;
    line-height: 1.2;
    font-weight: unset;
    margin: 20px;
    padding: 100px;
}

tbody td{
    padding-bottom: 20px;

}



.column1 {
    width: 260px;
    padding-left: 40px;
}

.column2 {
    width: 160px;
    text-align: center;
}

.column3 {
    width: 245px;
    text-align: center;
}





