

.contact-container{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}


.contact-info{

    align-items: flex-start;
    border-bottom-width: 10rem;
    margin: 10px;


}

.contact-middle{
    align-self: center;

}


.google-map iframe{

    top: 0;
    left: 0;
    width: 100%;


}

.vraagArena{
    width: 60%;
    display: block;
    margin-left: auto;
    margin-right: auto;
}



@media (max-width: 701px) {
    .google-map{

        border:1px solid black;
        margin-bottom: 30px;
        margin-top: 20px;
        width: 100%;

    }

}
@media (min-width: 701px) {
    .google-map{

        border:1px solid black;
        margin-top: 20px;
        margin-bottom: 30px;
        width: 700px;

    }

}

.eindediv{

    height: 300px;
}


.emailformcontainer{

    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;



}

