





.each-slide > div {
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;

}

img{
    max-width: 100%;
    height: auto;
}


@media (max-width: 700px) {
    .slide-container {
        width: 100%;
        margin: auto; }
}
@media (min-width: 700px) {
    .slide-container {
        min-width: 700px;
        width: 60%;
        margin: auto; }
}


