
.toolbar{
    width: 100%;
    background: rgb(221,185,29);

}

.toolbar_navigation{
    display: flex;
    height: 100%;
    align-items: center;
    padding: 0 1rem;

}


.toolbar_logo{

    width: 100%;

    text-align: center;
    display: block;
}



.toolbar_navigation-items{
    align-items: center;
    text-align: center;
    width: 100%;
    display: block;
}



.toolbar_navigation-items ul{
    list-style: none;
    margin: 0;
    padding: 0;
    display:  inline-block;



}

.toolbar_navigation-items li{
    float: left;
    padding: 0 1.5rem;
}


.toolbar_navigation-items a{

    color: white;
    text-decoration: none;

}

.toolbar_navigation-items a:hover,
.toolbar_navigation-items a:active{
    color: orange;
}



@media (max-width: 700px) {
    .toolbar_navigation-items{
        display: none;
    }

}

@media (min-width: 701px) {

    .toolbar_toggle-button{
        display: none;
    }

}
