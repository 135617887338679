.side-drawer {
    height: 100%;
    background: white;
    box-shadow: 1px 0 7px rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    width: 60%;
    max-width: 400px;
    z-index: 200;
    transform: translateX(-100%);
    transition: transform 0.5s ease-out;
}

.side-drawer.open {
    transform: translateX(0);
    align-items: center;
}

.sidebar-logo-container{

    width: 100%;

}
.sidebar-logo{

    display: table;
    margin: 0 auto;

}

.sidebar-strook{
    position: relative;
    width: 100%;
    height: 25px;
    background: rgb(221,185,29);



}


.side-drawer ul {

    list-style: none;
    display: flex;
    flex-direction: column;
}

.side-drawer li{
    width: 50%;
    height: 60px;
    padding-top: 1rem;
    padding-bottom: 1rem;
    margin-left: auto;
    margin-right: auto;
}


.side-drawer a {

    color: black;
    text-decoration: none;
    font-size: 1.2rem;
}


.side-drawer a:hover,
.side-drawer a:active{
    color: orange;

}

@media (min-width: 701px) {
    .side-drawer{
        display: none;
    }
    
}






